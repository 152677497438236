/* eslint-disable */
export default [
  {
    header: 'Ressources Humaines',
    action: 'read_all_employees',
    resource: 'Employés',
  },
  {
    title: 'Employés',
    route: 'apps-employees-list',
    icon: 'AtSignIcon',
    action: 'read_all_employees',
    resource: 'Employés',
  },
  // {
  //   title: "Salaires",
  //   route: "apps-salaries-list",
  //   icon: "FileTextIcon",
  //   action: 'read_employee',
  //   resource: 'Salaires',
  // },
  // {
  //   title: "Congés et Absences",
  //   route: "apps-statistic",
  //   icon: "ClipboardIcon",
  //   action: 'read_statistic',
  //   resource: 'Employés',
  // },
]