/* eslint-disable */
export default [
  {
    header: 'Finances',
    action: 'read_transactions_fund_history',
    resource: 'Caisse Générale',
  },
  {
    title: 'Caisse Générale',
    route: 'fund-histories-list',
    icon: 'DollarSignIcon',
    action: 'read_transactions_fund_history',
    resource: 'Caisse Générale',
  },
  {
    title: "Statistiques",
    route: "apps-statistic",
    icon: "TrendingUpIcon",
    action: 'read_statistic',
    resource: 'Statistiques',
  },
  {
    title: "Facturation",
    route: "apps-invoice-list",
    icon: "CreditCardIcon",
    action: 'read_all_invoices',
    resource: 'Facturation',
  }
]