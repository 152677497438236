/* eslint-disable */
export default [
  {
    header: "Paramètres",
    action: "read_project_status",
    resource: "Statut Projet",
  },
  {
    title: "Statut Projet",
    route: "apps-project-status-list",
    icon: "GridIcon",
    action: "read_project_status",
    resource: "Statut Projet",
  },
  {
    title: "Taxes",
    route: "apps-taxes-list",
    icon: "BarChart2Icon",
    action: "read_taxes",
    resource: "Taxes",
  },
  {
    title: "Pays",
    route: "apps-coutries-list",
    icon: "FlagIcon",
    action: "read_countries",
    resource: "Pays",
  },
  {
    title: "Marques",
    route: "apps-item-brands-list",
    icon: "TypeIcon",
    action: "read_all_brands",
    resource: "Marques",
  },
  {
    title: "Catégories",
    route: "apps-item-categories-list",
    icon: "TagIcon",
    action: "read_all_categories",
    resource: "Catégories",
  },
];
