<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-toast
      id="online-status-toast"
      variant="danger"
      no-close-button
      auto-hide-delay="10000"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-25">
          <feather-icon
        icon="AlertCircleIcon"
        class="mr-25"
      />
          <strong class="mr-auto">Problème connexion</strong>
        </div>
      </template>
      <span>Vous n'êtes pas connecté à internet.</span> <a href="/">Rafraîchir</a>
    </b-toast>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        v-if="me"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ me.name }}
            </p>
            <span class="user-status">{{ me.role.name }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="me.avatar"
            :text="avatarText(me.name)"
            class="badge-minimal"
            :badge-variant="online ? 'success' : 'danger'"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'apps-account-setting' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Tâches</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Déconnexion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-spinner v-else-if="isFetching" label="Loading..." />
      <div v-else @click="logout">
        <i class="mr-1">Utilisateur non connecté</i>
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="cursor-pointer text-danger"
        />
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSpinner,
  BToast,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'

export default {
  name: "NavBar",
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BSpinner,
    BToast,

    // Navbar Components
    DarkToggler,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      avatarText,
    };
  },
  watch: {
    online: function (newVal, oldVal) {
        if(newVal == false) {
          console.log(newVal)
          this.toast()
        }
    }
  },
  computed: {
    ...mapGetters("auth", {
      me: "me",
      isFetching: "fetchingUser",
      online: "isOnline"
    }),
  },
  mounted() {
    setInterval(() => {
      this.checkOnlineStatus()
    }, 60000);
  },
  methods: {
    ...mapActions('auth', ['checkOnlineStatus']),
    toast() {
      this.$bvToast.show("online-status-toast")
    },
    async logout() {
      try {
        const response = await this.$store.dispatch("auth/logout");
        // Remove userData from localStorage
        if (response.status == 202) {
          localStorage.clear();
          // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data?.message,
              icon: "CoffeeIcon",
              variant: "success",
            },
          });

          // Redirect to login page
          this.$router.push({ name: "login" });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Impossible de déconnecter l'Utilisateur",
              icon: "CoffeeIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        if(error.message == 'Network Error') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Vous rencontrez un problème de connexion",
              icon: "WifiOffIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message,
              icon: "WifiOffIcon",
              variant: "danger",
            },
          });
        }
      }
    },
  },
};
</script>
