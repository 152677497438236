/* eslint-disable */
export default [
  {
    header: 'Stockage',
    action: 'read_archives',
    resource: 'Bibliothèque',
  },
  {
    title: 'Bibliothèque',
    route: 'files-manager-home',
    icon: 'FolderIcon',
    action: 'read_archives',
    resource: 'Bibliothèque',
  },
]