import dashboard from "./dashboard";
import filesManager from "./files-manager";
import projectsAndTasks from "./projects-and-tasks";
import finance from "./finance";
import humanRessources from "./human-ressources";
import users from "./users";
import setting from "./settings";
import stocks from "./stocks";

/**
 * Steps to add a menu
 * 
 * 1./ go to related file or create a new one (like dashboard.js, files-manager.js)
 * 2./ register the menu with its properties
 * 3./ add a menu title as module on APIi server side and link it to permissions in order to get it in ability list
 */
export default [
    ...dashboard,
    ...filesManager,
    ...projectsAndTasks,
    ...finance,
    ...humanRessources,
    ...stocks,
    ...users,
    ...setting,
]