/* eslint-disable */
export default [
  {
    header: "Gest. Utilisateurs",
    action: 'read_users',
    resource: 'Rôles',
  },
  {
    title: "Utilisateurs",
    route: "apps-users-list",
    icon: "UsersIcon",
    action: 'read_users',
    resource: 'Rôles',
  },
  {
    title: "Rôles",
    route: "apps-roles-list",
    icon: "UserIcon",
    action: 'read_users',
    resource: 'Rôles',
  },
];
