/* eslint-disable */
export default [
  {
    header: 'Projets & Tâches',
    action: 'read_all_project',
    resource: 'Projets',
  },
  {
    title: 'Projets',
    route: 'projects-list',
    icon: 'SaveIcon',
    action: 'read_all_project',
    resource: 'Projets',
  },
  {
    title: 'Tâches',
    route: 'todos',
    icon: 'CheckSquareIcon',
    action: 'read_all_tasks',
    resource: 'Tâches',
  },
]